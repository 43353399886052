<div class="d-flex key-facts-columns border-table">
  <div class="col-md-12 p-0">
    <mat-button-toggle-group
      class="toggle-group w-100 toggle-options"
      appearance="legacy"
      name="fontStyle"
      aria-label="Font Style"
    >
      <mat-button-toggle
        [ngClass]="{ 'active-toggle': typeOpt === 1 }"
        (click)="typeOpt = 1"
        class="toogle-btn"
      >
        {{ "transaction.private" | translate }}</mat-button-toggle
      >
      <mat-button-toggle
        [ngClass]="{
          disabled: !validateBomaList,
          'active-toggle': validateBomaList && typeOpt === 2
        }"
        (click)="typeOpt = 2"
        class="toogle-btn"
      >
        {{
          validateBomaList ? ("transaction.boma" | translate) : ""
        }}</mat-button-toggle
      >
    </mat-button-toggle-group>
  </div>
  <div
    class="column label-color flexB-60"
    *ngIf="transaction?.leasedArea && typeOpt === 1"
  >
    {{ "transaction.leasedArea" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="transaction?.leasedArea && typeOpt === 1"
  >
    {{ transaction?.leasedArea | localNumber: "1.0-0" }}
  </div>
  <div
    class="column label-color flexB-60"
    *ngIf="transaction?.monthlyRentPerArea && typeOpt === 1"
  >
    {{ "transaction.monthlyRentPerArea" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="transaction?.monthlyRentPerArea && typeOpt === 1"
  >
    {{ transaction?.monthlyRentPerArea | localNumber: "1.2-2" }}
  </div>
  <div
    class="column label-color flexB-60"
    *ngIf="transaction?.condo && typeOpt === 1"
  >
    {{ "transaction.monthlyCondoFees" | translate }}
  </div>
  <div class="column label-color2" *ngIf="transaction?.condo && typeOpt === 1">
    {{ transaction?.condo | localNumber: "1.0-0" }}
  </div>
  <div
    class="column label-color flexB-60"
    *ngIf="transaction?.tenantImprovements && typeOpt === 1"
  >
    {{ "transaction.tenantImprovements" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="transaction?.tenantImprovements && typeOpt === 1"
  >
    {{ transaction?.tenantImprovements | localNumber: "1.0-0" }}
  </div>
  <div
    class="column label-color flexB-60"
    *ngIf="transaction?.leasingCommission && typeOpt === 1"
  >
    {{ "transaction.leasingCommission" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="transaction?.leasingCommission && typeOpt === 1"
  >
    {{ transaction?.leasingCommission | localNumber: "1.0-0" }}
  </div>

  <div
    class="column label-color flexB-60"
    *ngIf="transaction?.bomaLeaseArea && typeOpt === 2"
  >
    {{ "transaction.bomaLeaseArea" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="transaction?.bomaLeaseArea && typeOpt === 2"
  >
    {{ transaction?.bomaLeaseArea | localNumber: "1.0-0" }}
  </div>
  <div
    class="column label-color flexB-60"
    *ngIf="
      transaction?.bomaLeaseArea != null &&
      transaction?.bomaLeaseArea != 0 &&
      typeOpt === 2
    "
  >
    {{ "transaction.bomaMonthlyRent" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="
      transaction?.bomaLeaseArea != null &&
      transaction?.bomaLeaseArea != 0 &&
      typeOpt === 2
    "
  >
    {{
      transaction?.monthlyRent / transaction?.bomaLeaseArea
        | localNumber: "1.2-2"
    }}
  </div>
  <div
    class="column label-color flexB-60"
    *ngIf="transaction?.bomaCondo && typeOpt === 2"
  >
    {{ "transaction.bomaCondo" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="transaction?.bomaCondo && typeOpt === 2"
  >
    {{ transaction?.bomaCondo | localNumber }}
  </div>
  <div
    class="column label-color flexB-60"
    *ngIf="transaction?.bomaTenantImprovements && typeOpt === 2"
  >
    {{ "transaction.bomaTenantImprovements" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="transaction?.bomaTenantImprovements && typeOpt === 2"
  >
    {{ transaction?.bomaTenantImprovements | localNumber }}
  </div>

  <div
    class="column label-color flexB-60"
    *ngIf="transaction?.bomaLeasingCommission && typeOpt === 2"
  >
    {{ "transaction.bomaLeasingCommission" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="transaction?.bomaLeasingCommission && typeOpt === 2"
  >
    {{ transaction?.bomaLeasingCommission | localNumber }}
  </div>
  <div
    class="column label-color flexB-60"
    *ngIf="transaction?.bomaOccupancyRate && typeOpt === 2"
  >
    {{ "transaction.bomaOccupancyRate" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="transaction?.bomaOccupancyRate && typeOpt === 2"
  >
    {{ transaction?.bomaOccupancyRate | localNumber }}%
  </div>
  <div class="column label-color flexB-60" *ngIf="transaction?.occupancyRate">
    {{ "transaction.occupancyRate" | translate }}
  </div>
  <div class="column label-color2" *ngIf="transaction?.occupancyRate">
    {{ transaction?.occupancyRate | localNumber: "1.0-0" }} %
  </div>

  <div
    class="column label-color flexB-60"
    *ngIf="transaction?.soldArea && typeOpt === 1"
  >
    {{ "transaction.privateSoldArea" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="transaction?.soldArea && typeOpt === 1"
  >
    {{ transaction?.soldArea | localNumber: "1.0-0" }}
  </div>
  <div
    class="column label-color flexB-60"
    *ngIf="transaction?.sellPricePerArea && typeOpt === 1"
  >
    {{ "transaction.privatePrice" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="transaction?.sellPricePerArea && typeOpt === 1"
  >
    {{ transaction?.sellPricePerArea | localNumber: "1.0-0" }}
  </div>
  <div class="column label-color flexB-60" *ngIf="transaction?.noi">
    {{ "transaction.propertyNoi" | translate }}
  </div>
  <div class="column label-color2" *ngIf="transaction?.noi">
    {{ transaction?.noi | localNumber: "1.0-0" }}
  </div>
  <div
    class="column label-color flexB-60"
    *ngIf="transaction?.bomaSoldArea && typeOpt === 2"
  >
    {{ "transaction.bomaSoldArea" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="transaction?.bomaSoldArea && typeOpt === 2"
  >
    {{ transaction?.bomaSoldArea | localNumber: "1.0-0" }}
  </div>
  <div
    class="column label-color flexB-60"
    *ngIf="transaction?.salePrice && transaction?.bomaSoldArea && typeOpt === 2"
  >
    {{ "transaction.bomaprice" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="transaction?.salePrice && transaction?.bomaSoldArea && typeOpt === 2"
  >
    {{
      transaction?.salePrice / transaction?.bomaSoldArea | localNumber: "1.0-0"
    }}
  </div>
  <div class="column label-color" *ngIf="transaction?.occupancyAtSale">
    {{ "transaction.occupancyAtSale" | translate }}
  </div>
  <div class="column label-color2" *ngIf="transaction?.occupancyAtSale">
    {{ transaction?.occupancyAtSale | localNumber: "1.0-0" }} %
  </div>
  <div class="column label-color" *ngIf="transaction?.moduleEfficiency">
    {{ "transaction.moduleEfficiency" | translate }}
  </div>
  <div class="column label-color2" *ngIf="transaction?.moduleEfficiency">
    {{ transaction?.moduleEfficiency }}%
  </div>
  <div
    class="column label-color flexB-60"
    *ngIf="transaction?.floorLoad && typeOpt === 1"
  >
    {{ "transaction.floorLoad" | translate }}
  </div>
  <div
    class="column label-color2"
    *ngIf="transaction?.floorLoad && typeOpt === 1"
  >
    {{ transaction?.floorLoad }}
  </div>
</div>
